@import '../../style/variables';

.EmailLink {
  display: inline-block;
  @media not print {
    cursor: pointer;
    text-decoration: underline;
  }
  a {
    text-underline: map-get($colours, 'flatBlue');
    color: map-get($colours, 'flatBlue');
    font-size: map-get($fontSize, 'xs');
  }
}
