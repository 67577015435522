@import '../../style/variables';

.Expandable {
  .chevron-container {
    display: flex!important;
  }

  &.collapsed {
    padding-bottom: 0;
    .content {
      @media not print {
          display: none!important;
      }
    }
  }

  > .top {
    cursor: pointer;
    display: grid;
    grid-template-columns: auto 50px;
    padding-bottom: 0;

    &:hover {
      opacity: 0.7;
    }

    .Chevron {
      display: flex;
      position: initial;
      margin: 0 auto;
    }
  }
  .bottom {

  }
}