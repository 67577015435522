@import './style/reset';
@import './style/variables';
@import './style/mixins';
@import './style/fonts';
@import './style/global';
@import './style/utils';

header {
  @media not print {
    display: grid;
    grid-auto-flow: column;
    grid-gap: map-get($spacing, 'md');
    grid-template-columns: 90px auto;
    @media only screen and (min-width: map-get($breakpoints, "md")) {
      grid-template-columns: 140px auto;
    }
  }
  .hide-md:first-of-type {
    display: grid;
    grid-auto-flow: column;
  }
  margin-bottom: map-get($spacing, 'lg');

  img {
    height: 90px;
    width: 90px;
    border-radius: 12px;
    @media only screen and (min-width: map-get($breakpoints, "md")) {
      height: 140px;
      width: 140px;
    }
  }

}

.skill-category {
  padding: map-get($spacing, 'xs');
  border-radius: map-get($borderRadius, 'md');
  border: 2px solid transparent;

  &.client-side {
    border-color: map-get($colours, 'flatGreen');
    color: map-get($colours, 'flatGreen');
  }
  &.server-side {
    border-color: map-get($colours, 'flatOrange');
    color: map-get($colours, 'flatOrange');
  }
  &.database {
    border-color: map-get($colours, 'flatTurqoise');
    color: map-get($colours, 'flatTurqoise');
  }
  &.infrastructure {
    border-color: map-get($colours, 'flatBlue');
    color: map-get($colours, 'flatBlue');
  }
  &.general {
    border-color: map-get($colours, 'flatRed');
    color: map-get($colours, 'flatRed');
  }
  &.tooling {
    border-color: map-get($colours, 'flatYellow');
    color: map-get($colours, 'flatYellow');
  }
}

.App {
  -webkit-print-color-adjust: exact !important;
  font-family: 'DM Sans', Arial, sans-serif;
  padding: map-get($spacing, 'md');

  @media only screen and (min-width: map-get($breakpoints, "md")) {
    display: grid;
    padding: map-get($spacing, 'xl');
  }
  @media not print {
    @media only screen and (min-width: map-get($breakpoints, "md")) {
      width: calc(100vw - map-get($asideWidth, "md"));
    }

    @media only screen and (min-width: map-get($breakpoints, "lg")) {
      width: calc(100vw - map-get($asideWidth, "lg"));
    }
  }
  @media not print {
    aside {
      ::-webkit-scrollbar {
        width: 6px;
        background: map-get($colours, 'primaryText');;
      }

      ::-webkit-scrollbar-thumb {
        background: #fff;
        -webkit-border-radius: 1ex;
        -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
      }

      ::-webkit-scrollbar-corner {
        background: #fff;
      }

      @media not print {
        @media only screen and (min-width: map-get($breakpoints, "md")) {
          width: map-get($asideWidth, "md");
          position: fixed;
          top: 0;
          right: 0;
          > div {
            overflow-y: auto;
            height: 100vh;
            padding: map-get($spacing, 'xl');
          }
        }
        @media only screen and (min-width: map-get($breakpoints, "lg")) {
          width: map-get($asideWidth, "lg");
        }
      }
    }
  }
  //}
}


