@import '../../style/variables';
@import '../../style/mixins';

.Experiences {
  p,
  .EmailLink {
    text-align: center;
  }
  .EmailLink {
    font-size: map-get($fontSize, 'xs');
  }

  .EmailLink,
  .experience-footnote {
  }

  .experience-footnote {
    color: map-get($colours, 'flatGreyMedium');
  }
}

.Experiences .Experience {
  margin-bottom: map-get($spacing, 'lg');

  .Expandable .header-content {
    display: grid;
    grid-template-columns: 37px auto;
    grid-gap: map-get($spacing, 'sm');
    margin-bottom: map-get($spacing, 'lg');
  }

    .head {
      flex-grow: 1;
      div.text {
        display: flex;
        flex-direction: row;
        :first-child {
          flex-grow: 1;
        }
      }

      h5 {
        white-space: nowrap;
      }
    }

  summary {
    font-weight: 700;
  }

  ul.contributions {
    margin-bottom: map-get($spacing, 'lg');

    li {
      list-style-type: circle;
      margin-left: 24px;
    }
  }

  ul.technologies li {
    font-weight: 700;
    display: inline-block;
    padding: map-get($spacing, 'xs');
    background-color: map-get($spacing, 'flatGreen');
    border-radius: map-get($borderRadius, 'md');
    color: map-get($colours, 'white');
    margin-right: map-get($spacing, 'xs');
  }

  div.image {
    border-radius: map-get($borderRadius, 'md');
    overflow: hidden;
    height: 37px;
    img {
      width: 37px;
      height: 37px;
    }
  }
}
