@import '../../style/variables';

.TechnicalSkillsGroupContainer {
  display: block;
  @media print, screen and (min-width: map-get($breakpoints, "md")) {
    .Chevron {
      display: none;
    }
  }

  > div {
    margin-bottom: map-get($spacing, "md");
  }
  @media print, screen and (min-width: map-get($breakpoints, "lg")) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: map-get($spacing, 'md');
  }

  svg {
    margin-right: map-get($spacing, 'xxs');
  }
  ul li {
    display: flex;
    font-weight: 700;
    margin-bottom: 0;
    span:first-child {
      white-space: nowrap;
      font-size: map-get($fontSize, 'xxs');
      flex-grow: 1;
      line-height: map-get($lineHeight, 'xxs');
    }
  }
}
