@import '../../style/mixins';
@import '../../style/variables';

.Chevron {
  position: absolute;
  right: px-to-rem(20);

  &.flipped {
    transform: rotate(180deg);
  }
  @media only screen and (min-width: map-get($breakpoints, "md")) {
    display: none;
  }
}