@import './variables';

@page {
  size: auto;
  margin: map-get($spacing, 'sm');
}

h1 {
  font-size: map-get($fontSize, 'xl');
  font-weight: 700;
  margin-bottom: map-get($spacing, 'md');
  @media only screen and (min-width: map-get($breakpoints, "md")) {
    font-size: map-get($fontSize, 'xxl');
    margin-bottom: map-get($spacing, 'xs');
  }
}

h2 {
  font-size: map-get($fontSize, 'm');
  font-weight: 300;
  margin-bottom: map-get($spacing, 'xs');
  @media only screen and (min-width: map-get($breakpoints, "md")) {
    margin-bottom: map-get($spacing, 'm');
  }
}

h3 {
  font-size: map-get($fontSize, 'lg');
  font-weight: 700;
  padding: map-get($spacing, 'lg') 0;
}

h4 {
  font-size: map-get($fontSize, 'sm');
  @media only screen and (min-width: map-get($breakpoints, "md")) {
    font-size: map-get($fontSize, 'md');
  }
  font-weight: 700;
  margin-bottom: map-get($spacing, 'xs');
}

h5 {
  font-size: map-get($fontSize, 'xs');
  @media only screen and (min-width: map-get($breakpoints, "md")) {
    font-size: map-get($fontSize, 'sm');
  }
  font-weight: 600;
  margin-bottom: map-get($spacing, 'xs');
}
h6 {
  margin-bottom: 6px;
  font-size: map-get($fontSize, 'xs');
}
p,
ul li {
  font-size: map-get($fontSize, 'xs');
  margin-bottom: map-get($spacing, 'xs');
  line-height: map-get($lineHeight, 'xs');
}

strong {
  font-weight: 700;
}
a {
  font-style: normal;
}

summary {
  margin-bottom: map-get($spacing, 'lg');
  font-size: px-to-rem(14);
  line-height: map-get($fontSize, 'lg');
  font-style: italic;
  margin-bottom: map-get($spacing, 'lg');
  //@include border-top-medium();
}