@font-face {
  font-family: 'DM Sans' ;
  font-style: normal;
  font-weight: 700;
  src: url(./fonts/DMSans-Bold.woff2) format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  font-style: italic;
  font-weight: 700;
  src: url(./fonts/DMSans-BoldItalic.woff2) format('woff2');
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  font-style: italic;
  font-weight: 400;
  src: url(./fonts/DMSans-Italic.woff2) format('woff2');
  font-display: swap;
}

//@font-face {
//  font-family: 'DM Sans';
//  font-style: normal;
//  font-weight: 500;
//  src: url(./fonts/DMSans-Medium.woff2) format('woff2');
//}
//
//@font-face {
//  font-family: 'DM Sans';
//  font-style: italic;
//  font-weight: 500;
//  src: url(./fonts/DMSans-MediumItalic.woff2) format('woff2');
//}

@font-face {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/DMSans-Regular.woff2) format('woff2');
  font-display: swap;
}