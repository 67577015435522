@import './variables';

.only-print {
  @media not print {
    display: none !important;
  }
  @media print {
    display: initial !important;
  }
}

.no-print {
  @media not print {
    display: block !important;
  }
  @media print {
    &, * {
      display: none !important;
    }
  }
}

.hide-md {
    @media not print {
        @media screen and (min-width: map-get($breakpoints, "md")) {
        display: none !important;
        }
    }
}
.md {
  display: none;
    @media not print {
        @media screen and (min-width: map-get($breakpoints, "md")) {
        display: block !important;
        }
    }
}

.dark * {
  @media not print {
    @media screen and (min-width: map-get($breakpoints, "md")) {
      color: map-get($colours, 'bright');
      background-color: map-get($colours, 'primaryText');
      path {
       fill: map-get($colours, 'bright');
      }
    }
  }
}

.border-bottom {
  margin-bottom: map-get($spacing, 'lg');
  padding-bottom: map-get($spacing, 'lg');
  border-bottom: 1px solid map-get($colours, 'flatGreyMedium');
}

.border-bottom-medium {
  margin-bottom: map-get($spacing, 'md');
  padding-bottom: map-get($spacing, 'md');
  border-bottom: 1px solid map-get($colours, 'flatGreyLight');
}